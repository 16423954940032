import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import snackbar from 'utils/snackbar';
import { IWebcontent, WebcontentType } from '../../../@types/webcontent';
import webcontentApi from '../../../apis/webcontent.api';

export const getWebcontentPage = createAsyncThunk(
    'webcontent/getList',
    async (type: string, { dispatch }) => {
        const { data } = await webcontentApi.get(type);
        return data;
    }
);
export const updateWebcontent = createAsyncThunk(
    'service/create',
    async (data: IWebcontent, { dispatch }) => {
        await webcontentApi.post(data);
        snackbar.success(i18next.t('updateSuccess'));
        dispatch(getWebcontentPage(data.type));
    }
);

type IListPage = {
    home?: IWebcontent[];
    aboutUs?: IWebcontent[];
    constructionDesign?: IWebcontent[];
    eventOrganize?: IWebcontent[];
    hrm?: IWebcontent[];
    serviceOther?: IWebcontent[];
};

const initialState: IListPage = {
    home: undefined,
    aboutUs: undefined,
    constructionDesign: undefined,
    eventOrganize: undefined,
    hrm: undefined,
    serviceOther: undefined,
};

const slice = createSlice({
    name: 'webcontent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getWebcontentPage.fulfilled, (state, action) => {
            const { type } = action.payload[0];
            switch (type) {
                case WebcontentType.Home:
                    state.home = action.payload;
                    break;
                case WebcontentType.ConstructionDesign:
                    state.constructionDesign = action.payload;
                    break;
                case WebcontentType.AboutUs:
                    state.aboutUs = action.payload;
                    break;
                case WebcontentType.EventOrganize:
                    state.eventOrganize = action.payload;
                    break;
                case WebcontentType.HRM:
                    state.hrm = action.payload;
                    break;
                case WebcontentType.ServiceOther:
                    state.serviceOther = action.payload;
                    break;

                default:
                    break;
            }
        });
    },
});
export default slice.reducer;
