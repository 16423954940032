import { deleteAsync, getAsync, postAsync } from 'apis/http-client';
import { IGift } from '../@types/gift';
import { ParamsType } from '../@types/paramsType';

const GiftApi = {
  create: (data: IGift) => {
    return postAsync(`/product`, data);
  },
  get: (data: ParamsType) => {
    return getAsync(`/product`, data);
  },
  getDetail: (id: number | string) => {
    return getAsync(`/product/${id}`);
  },
  delete: (data: number[] | string[]) => {
    return deleteAsync(`/product`, data);
  },
};

export default GiftApi;
