import { ElementType, Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD
// Job
export const CreateJobPage = Loadable(lazy(() => import('../pages/dashboard/job/create')));
export const JobListPage = Loadable(lazy(() => import('../pages/dashboard/job/index')));
export const EditJobPage = Loadable(lazy(() => import('../pages/dashboard/job/edit')));
// Job Apply
export const JobApplyPage = Loadable(lazy(() => import('../pages/dashboard/job-apply/index')));

// Product
export const CreateProductPage = Loadable(lazy(() => import('../pages/dashboard/product/create')));
export const ProductListPage = Loadable(lazy(() => import('../pages/dashboard/product/index')));
export const EditProductPage = Loadable(lazy(() => import('../pages/dashboard/product/edit')));

// Order
export const OrderListPage = Loadable(lazy(() => import('../pages/dashboard/order/index')));
export const CreateOrderPage = Loadable(lazy(() => import('../pages/dashboard/order/create')));
export const EditOrderPage = Loadable(lazy(() => import('../pages/dashboard/order/edit')));

// Event
export const EventListPage = Loadable(lazy(() => import('../pages/dashboard/event/index')));
export const CreateEventPage = Loadable(lazy(() => import('../pages/dashboard/event/create')));
export const EditEventPage = Loadable(lazy(() => import('../pages/dashboard/event/edit')));

// Events
export const IntroduceEventPage = Loadable(
  lazy(() => import('../pages/dashboard/events/introduce/index'))
);
export const ProcedureEventPage = Loadable(
  lazy(() => import('../pages/dashboard/events/procedure/index'))
);
export const ServiceEventListPage = Loadable(
  lazy(() => import('../pages/dashboard/events/service/index'))
);
export const CreateServiceEventPage = Loadable(
  lazy(() => import('../pages/dashboard/events/service/create/index'))
);

export const EditServiceEventPage = Loadable(
  lazy(() => import('../pages/dashboard/events/service/edit/index'))
);
// Construction Design
export const IntroduceConstructionDesignPage = Loadable(
  lazy(() => import('../pages/dashboard/construction-design/introduce/index'))
);
export const ProcedureConstructionDesignPage = Loadable(
  lazy(() => import('../pages/dashboard/construction-design/procedure/index'))
);
export const ServiceConstructionDesignListPage = Loadable(
  lazy(() => import('../pages/dashboard/construction-design/service/index'))
);
export const CreateServiceConstructionDesignPage = Loadable(
  lazy(() => import('../pages/dashboard/construction-design/service/create/index'))
);

export const EditServiceConstructionDesignPage = Loadable(
  lazy(() => import('../pages/dashboard/construction-design/service/edit/index'))
);
// Project Image
export const ProjectImagePage = Loadable(
  lazy(() => import('../pages/dashboard/project-image/index'))
);
export const PageImagePage = Loadable(lazy(() => import('../pages/dashboard/page-image/index')));
export const WebBannerPage = Loadable(lazy(() => import('../pages/dashboard/web-banner/index')));

// other service
export const GiftPage = Loadable(lazy(() => import('../pages/dashboard/other-service/gift/index')));
export const CreateGiftPage = Loadable(
  lazy(() => import('../pages/dashboard/other-service/gift/create'))
);
export const EditGiftPage = Loadable(
  lazy(() => import('../pages/dashboard/other-service/gift/edit'))
);

export const FurnitureProductPage = Loadable(
  lazy(() => import('../pages/dashboard/other-service/furniture-product/index'))
);

export const CreateFurnitureProductPage = Loadable(
  lazy(() => import('../pages/dashboard/other-service/furniture-product/create'))
);

export const EditFurnitureProductPage = Loadable(
  lazy(() => import('../pages/dashboard/other-service/furniture-product/edit'))
);
// Edit Image Webiste
export const Menu = Loadable(lazy(() => import('../pages/dashboard/edit-website/menu/Menu')));
export const HomePage = Loadable(lazy(() => import('../pages/dashboard/edit-website/home/Home')));
export const AboutUsPage = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/about-us/AboutUs'))
);
export const ServicePage = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/service/Service'))
);
export const StorePage = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/store/Store'))
);
export const ContactPage = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/contact/Contact'))
);

export const EditHome = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/home-page/Home'))
);
export const EditAboutUs = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/about-us-page/AboutUs'))
);
export const EditConstructionDesign = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/construction-design/ConstructionDesign'))
);
export const EditEventOrganization = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/event-organization/EventOrganization'))
);
export const EditHRM = Loadable(lazy(() => import('../pages/dashboard/edit-website/hrm/HRM')));
export const EditServiceOther = Loadable(lazy(() => import('../pages/dashboard/edit-website/service-other/ServiceOther')));
// Contact
export const ContactListPage = Loadable(lazy(() => import('../pages/dashboard/contact/index')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));

// Edit website
export const AboutUs = Loadable(
  lazy(() => import('../pages/dashboard/edit-website/about-us/AboutUs'))
);
// DASHBOARD: FILE MANAGER
export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
