export type IWebcontent = {
    id: number;
    type: WebcontentType;
    content: string;
};

export enum WebcontentType {
    Home = 'home',
    AboutUs = 'aboutUs',
    ConstructionDesign = 'constructionDesign',
    EventOrganize = 'eventOrganize',
    HRM = 'hrm',
    ServiceOther = 'serviceOther',
}
