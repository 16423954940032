import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { IGift } from '../../../@types/gift';
import GiftApi from '../../../apis/gift.api';
import SnakeBar from '../../../utils/snackbar';
import { ParamsType } from '../../../@types/paramsType';
import { OBJECT_TYPE } from '../../../constants/app.constants';

// ----------------------------------------------------------------------
type IDataCreated = {
  data: IGift;
  id?: number | string;
  navigate: () => void;
};
type IGiftDelete = {
  ids: number[] | string[];
  params: ParamsType;
};

export const createGift = createAsyncThunk(
  'gift/createGift',
  async (data: IDataCreated, { dispatch }) => {
    await GiftApi.create({ ...data.data, categories: OBJECT_TYPE.otherService.gift });
    if (data.data.id === 0) {
      SnakeBar.success(i18next.t('createSuccess'));
    } else {
      SnakeBar.success(i18next.t('editSuccess'));
    }
    data.navigate();
  }
);

export const getListGift = createAsyncThunk(
  'Gift/getListGift',
  async (params: ParamsType, { dispatch }) => {
    const { data } = await GiftApi.get({ ...params, categoryId: OBJECT_TYPE.otherService.gift });
    return data;
  }
);
export const getGiftDetail = createAsyncThunk(
  'Gift/getGiftDetail',
  async (id: number | string, { dispatch }) => {
    const { data } = await GiftApi.getDetail(id);
    return data;
  }
);

export const deleteGift = createAsyncThunk(
  'Gift/delete',
  async (data: IGiftDelete, { dispatch }) => {
    await GiftApi.delete(data.ids);
    SnakeBar.success(i18next.t('deleteSuccess'));
    await dispatch(getListGift(data.params));
  }
);

type IGiftState = {
  listGift: IGift[];
  giftCount: number;
  giftDetail: IGift;
};

const initialState: IGiftState = {
  listGift: [],
  giftCount: 0,
  giftDetail: {
    id: 0,
    name: '',
    thumbnail: '',
    material: '',
    specifications: '',
  },
};

const slice = createSlice({
  name: 'Gift',
  initialState,
  reducers: {
    filterGift: (state, action) => {
      const idGift = action.payload;
      state.listGift.filter((item) => item.id !== idGift);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListGift.fulfilled, (state, action) => {
      state.listGift = action.payload.items;
      state.giftCount = action.payload.totalRow;
    });
    builder.addCase(getGiftDetail.fulfilled, (state, action) => {
      state.giftDetail = action.payload;
    });
  },
});
export default slice.reducer;
export const { filterGift } = slice.actions;
