import { deleteAsync, getAsync, postAsync } from './http-client';

const ObjectType = {
  get: (params: any) => {
    return getAsync(`/objecttype`, params);
  },
  post: (params: { id: number | string; objectType: string; objectName: string }) => {
    return postAsync(`/objecttype`, params);
  },
};

export default ObjectType;
