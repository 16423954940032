import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { IFurnitureProduct } from '../../../@types/FurnitureProduct';
import { ParamsType } from '../../../@types/paramsType';
import SnakeBar from '../../../utils/snackbar';
import FurnitureProductApi from '../../../apis/FurnitureProduct.api';
import { OBJECT_TYPE } from '../../../constants/app.constants';

// ----------------------------------------------------------------------
type IDataCreated = {
  data: IFurnitureProduct;
  id?: number | string;
  navigate: () => void;
};
type IFurnitureProductDelete = {
  ids: number[] | string[];
  params: ParamsType;
};

export const createFurnitureProduct = createAsyncThunk(
  'furnitureProduct/createFurnitureProduct',
  async (data: IDataCreated, { dispatch }) => {
    await FurnitureProductApi.create({
      ...data.data,
      categories: OBJECT_TYPE.otherService.furnitureProduct,
    });
    if (data.data.id === 0) {
      SnakeBar.success(i18next.t('createSuccess'));
    } else {
      SnakeBar.success(i18next.t('editSuccess'));
    }
    data.navigate();
  }
);

export const getListFurnitureProduct = createAsyncThunk(
  'FurnitureProduct/getListFurnitureProduct',
  async (params: ParamsType, { dispatch }) => {
    const { data } = await FurnitureProductApi.get({
      ...params,
      categoryId: OBJECT_TYPE.otherService.furnitureProduct,
    });
    return data;
  }
);
export const getFurnitureProductDetail = createAsyncThunk(
  'FurnitureProduct/getFurnitureProductDetail',
  async (id: number | string, { dispatch }) => {
    const { data } = await FurnitureProductApi.getDetail(id);
    return data;
  }
);

export const deleteFurnitureProduct = createAsyncThunk(
  'FurnitureProduct/delete',
  async (data: IFurnitureProductDelete, { dispatch }) => {
    await FurnitureProductApi.delete(data.ids);
    SnakeBar.success(i18next.t('deleteSuccess'));
    await dispatch(getListFurnitureProduct(data.params));
  }
);

type IFurnitureProductState = {
  listFurnitureProduct: IFurnitureProduct[];
  furnitureProductCount: number;
  furnitureProductDetail: IFurnitureProduct;
};

const initialState: IFurnitureProductState = {
  listFurnitureProduct: [],
  furnitureProductCount: 0,
  furnitureProductDetail: {
    id: 0,
    name: '',
    thumbnail: '',
    material: '',
    specifications: '',
  },
};

const slice = createSlice({
  name: 'furnitureProduct',
  initialState,
  reducers: {
    filterFurnitureProduct: (state, action) => {
      const idFurnitureProduct = action.payload;
      state.listFurnitureProduct.filter((item) => item.id !== idFurnitureProduct);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListFurnitureProduct.fulfilled, (state, action) => {
      state.listFurnitureProduct = action.payload.items;
      state.furnitureProductCount = action.payload.totalRow;
    });
    builder.addCase(getFurnitureProductDetail.fulfilled, (state, action) => {
      state.furnitureProductDetail = action.payload;
    });
  },
});
export default slice.reducer;
export const { filterFurnitureProduct } = slice.actions;
