import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import i18next from 'i18next';
import SnakeBar from 'utils/snackbar';

import {
  IServiceEventCreated,
  IServiceEventParams,
  IServiceEventState,
} from '../../../@types/serviceEvent';
import objectTypeApi from '../../../apis/objecType.api';
import ServiceEventApi from '../../../apis/serviceEvent.api';

type IServiceEventCreatedAction = {
  data: IServiceEventCreated;
  navigate: (path: string) => void;
};
type IServiceEventDelete = {
  ids: number[] | string[];
  params: IServiceEventParams;
};
// ----------------------------------------------------------------------

export const getObjectTypeEvent = createAsyncThunk(
  'event/getListObjectType',
  async (params: any, { dispatch }) => {
    const { data } = await objectTypeApi.get({ objectType: params });
    return data;
  }
);

export const getListServiceEvent = createAsyncThunk(
  'event/getListEvent',
  async (params: IServiceEventParams, { dispatch }) => {
    const { data } = await ServiceEventApi.get(params);
    return data;
  }
);

export const getServiceIntroduce = createAsyncThunk(
  'event/introduce',
  async (params: string, { dispatch }) => {
    const { data } = await objectTypeApi.get({ objectType: params });
    return data;
  }
);

export const getServiceProcedure = createAsyncThunk(
  'event/procedure',
  async (params: string, { dispatch }) => {
    const { data } = await objectTypeApi.get({ objectType: params });
    return data;
  }
);

export const createServiceEvent = createAsyncThunk(
  'event/createEvent',
  async (submitData: IServiceEventCreatedAction, { dispatch }) => {
    const { data } = await ServiceEventApi.create(submitData.data);

    if (submitData.data.id > 0) {
      SnakeBar.success(i18next.t('updateSuccess'));
    } else {
      SnakeBar.success(i18next.t('createSuccess'));
    }
    submitData?.navigate(data?.toString());
  }
);

export const getServiceEventDetail = createAsyncThunk(
  'event/getEventDetail',
  async (id: number, { dispatch }) => {
    const { data } = await ServiceEventApi.getDetail(id);
    return data;
  }
);

export const deleteServiceEvent = createAsyncThunk(
  'event/deleteEvent',
  async (data: IServiceEventDelete, { dispatch }) => {
    try {
      await ServiceEventApi.delete(data.ids);
      await dispatch(getListServiceEvent(data.params));
      SnakeBar.success(i18next.t('deleteSuccess'));
    } catch (error) {
      SnakeBar.error(i18next.t('deleteError'));
    }
  }
);

const initialState: IServiceEventState = {
  currentIndex: 0,
  serviceEventList: [],
  sericeEventCount: 0,
  serviceEventDetail: {
    id: 0,
    images: '',
  },
  serviceEventType: [],
  serviceIntroduce: {
    id: 0,
    objectCode: '',
    objectName: '',
    objectType: '',
  },
  serviceProcedure: {
    id: 0,
    objectCode: '',
    objectName: '',
    objectType: '',
  },
};

const slice = createSlice({
  name: 'Event',
  initialState,
  reducers: {
    setCurrentIndex(state, action) {
      state.currentIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListServiceEvent.fulfilled, (state, action) => {
        state.serviceEventList = action.payload.items;
        state.sericeEventCount = action.payload.totalRow;
      })
      .addCase(getObjectTypeEvent.fulfilled, (state, action) => {
        state.serviceEventType = action.payload;
      })
      .addCase(getServiceEventDetail.fulfilled, (state, action) => {
        state.serviceEventDetail = action.payload;
      })
      .addCase(getServiceIntroduce.fulfilled, (state, action) => {
        state.serviceIntroduce = action.payload[0];
      })
      .addCase(getServiceProcedure.fulfilled, (state, action) => {
        state.serviceIntroduce = action.payload[0];
      });
  },
});

export const { setCurrentIndex } = slice.actions;
export default slice.reducer;
