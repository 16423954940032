import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from 'auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';

// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  AboutUs,
  BlankPage,
  ComingSoonPage,
  ContactListPage,
  ContactPage,
  CreateEventPage,
  CreateFurnitureProductPage,
  CreateGiftPage,
  CreateJobPage,
  CreateOrderPage,
  CreateProductPage,
  EditEventPage,
  EditFurnitureProductPage,
  EditGiftPage,
  EditJobPage,
  EditOrderPage,
  EditProductPage,
  // Dashboard: Ecommerce
  EventListPage,
  // Dashboard: FileManager
  FileManagerPage,
  FurnitureProductPage,
  GiftPage,
  HomePage,
  JobApplyPage,
  JobListPage,
  LoginPage,
  MaintenancePage,
  NewPasswordPage,
  OrderListPage,
  Page403,
  Page404,
  //
  Page500,
  PageImagePage,
  PermissionDeniedPage,
  ProductListPage,
  ProjectImagePage,
  RegisterPage,
  ResetPasswordPage,
  ServicePage,
  StorePage,
  UserAccountPage,
  UserCardsPage,
  UserCreatePage,
  UserEditPage,
  // Dashboard: User
  UserListPage,
  UserProfilePage,
  VerifyCodePage,
  WebBannerPage,

  // events
  ServiceEventListPage,
  CreateServiceEventPage,
  IntroduceEventPage,
  ProcedureEventPage,
  EditServiceEventPage,
  IntroduceConstructionDesignPage,
  ServiceConstructionDesignListPage,
  CreateServiceConstructionDesignPage,
  EditServiceConstructionDesignPage,
  ProcedureConstructionDesignPage,
  Menu,
  EditHome,
  EditAboutUs,
  EditConstructionDesign,
  EditEventOrganization,
  EditHRM,
  EditServiceOther,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'home',
          children: [
            { element: <Navigate to="/" replace />, index: true },
            {
              path: 'hrm',
              children: [
                {
                  path: 'recruitment',
                  children: [
                    {
                      path: 'job',
                      // element: <JobListPage />,
                      children: [
                        {
                          path: '/dashboard/home/hrm/recruitment/job/list',
                          element: <JobListPage />,
                        },
                        {
                          path: 'new',
                          element: <CreateJobPage />,
                        },
                        {
                          path: '/dashboard/home/hrm/recruitment/job/edit/:id',
                          element: <EditJobPage />,
                        },
                      ],
                    },
                    {
                      path: 'apply',
                      element: <JobApplyPage />,
                    },
                  ],
                },

                // { element: <Navigate to="/dashboard/home/job/list" replace />, index: true },
                // {
                //   path: 'list',
                //   element: <JobListPage />,
                // },
                // {
                //   path: 'new',
                //   element: <CreateJobPage />,
                // },
                // {
                //   path: 'edit/:id',
                //   element: <EditJobPage />,
                // },
                // {
                //   path: 'apply',
                //   element: <JobApplyPage />,
                // },
                // {
                //   path: 'apply/new',
                //   element: <CreateJobApplyPage />,
                // },
              ],
            },
            {
              path: 'product',
              children: [
                {
                  path: '/dashboard/home/product',
                  element: <ProductListPage />,
                },
                {
                  path: '/dashboard/home/product/new',
                  element: <CreateProductPage />,
                },
                {
                  path: '/dashboard/home/product/:id',
                  element: <EditProductPage />,
                },
              ],
            },
            {
              path: 'other-service',
              children: [
                {
                  path: 'gift',
                  children: [
                    {
                      path: '/dashboard/home/other-service/gift',
                      element: <GiftPage />,
                    },
                    {
                      path: '/dashboard/home/other-service/gift/new',
                      element: <CreateGiftPage />,
                    },
                    {
                      path: '/dashboard/home/other-service/gift/:id',
                      element: <EditGiftPage />,
                    },
                  ],
                },
                {
                  path: 'furniture-product',
                  children: [
                    {
                      path: '/dashboard/home/other-service/furniture-product',
                      element: <FurnitureProductPage />,
                    },
                    {
                      path: '/dashboard/home/other-service/furniture-product/new',
                      element: <CreateFurnitureProductPage />,
                    },
                    {
                      path: '/dashboard/home/other-service/furniture-product/:id',
                      element: <EditFurnitureProductPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'edit-website',
              children: [
                {
                  path: '/dashboard/home/edit-website/home',
                  element: <EditHome />,
                },
                {
                  path: '/dashboard/home/edit-website/about-us-page',
                  element: <EditAboutUs />,
                },
                {
                  path: '/dashboard/home/edit-website/construction-design',
                  element: <EditConstructionDesign />,
                },
                {
                  path: '/dashboard/home/edit-website/events',
                  element: <EditEventOrganization />,
                },

                {
                  path: '/dashboard/home/edit-website/hrm',
                  element: <EditHRM />,
                },
                {
                  path: '/dashboard/home/edit-website/service-other',
                  element: <EditServiceOther />,
                },
                {
                  path: '/dashboard/home/edit-website/menu',
                  element: <Menu />,
                },
                {
                  path: '/dashboard/home/edit-website/home-page',
                  element: <HomePage />,
                },
                {
                  path: '/dashboard/home/edit-website/about-us',
                  element: <AboutUs />,
                },

                {
                  path: '/dashboard/home/edit-website/service',
                  element: <ServicePage />,
                },
                {
                  path: '/dashboard/home/edit-website/store',
                  element: <StorePage />,
                },
                {
                  path: '/dashboard/home/edit-website/contact',
                  element: <ContactPage />,
                },

                {
                  path: '/dashboard/home/edit-website/project-image',
                  element: <ProjectImagePage />,
                },
                {
                  path: '/dashboard/home/edit-website/page-image',
                  element: <PageImagePage />,
                },
                {
                  path: '/dashboard/home/edit-website/web-banner',
                  element: <WebBannerPage />,
                },
              ],
            },
            {
              path: 'order',
              children: [
                {
                  path: '/dashboard/home/order',
                  element: <OrderListPage />,
                },
                {
                  path: '/dashboard/home/order/new',
                  element: <CreateOrderPage />,
                },
                {
                  path: '/dashboard/home/order/edit/:id',
                  element: <EditOrderPage />,
                },
              ],
            },
            {
              path: 'event',
              children: [
                {
                  path: '/dashboard/home/event',
                  element: <EventListPage />,
                },
                {
                  path: '/dashboard/home/event/new',
                  element: <CreateEventPage />,
                },
                {
                  path: '/dashboard/home/event/edit/:id',
                  element: <EditEventPage />,
                },
              ],
            },

            {
              path: 'web-banner',
              children: [
                {
                  path: '/dashboard/home/web-banner',
                  element: <WebBannerPage />,
                },
              ],
            },

            {
              path: 'contact',
              children: [
                {
                  path: '/dashboard/home/contact',
                  element: <ContactListPage />,
                },
              ],
            },
            {
              path: 'events',
              children: [
                {
                  path: 'introduce',
                  element: <IntroduceEventPage />,
                },
                {
                  path: 'service',
                  children: [
                    {
                      path: '/dashboard/home/events/service',
                      element: <ServiceEventListPage />,
                    },
                    {
                      path: '/dashboard/home/events/service/new',
                      element: <CreateServiceEventPage />,
                    },
                    {
                      path: '/dashboard/home/events/service/:id',
                      element: <EditServiceEventPage />,
                    },
                  ],
                },
                {
                  path: 'procedure',
                  element: <ProcedureEventPage />,
                },
                // {
                //   path: '/dashboard/home/event/new',
                //   element: <CreateEventPage />,
                // },
                // {
                //   path: '/dashboard/home/event/edit/:id',
                //   element: <EditEventPage />,
                // },
              ],
            },
            {
              path: 'construction-design',
              children: [
                {
                  path: 'introduce',
                  element: <IntroduceConstructionDesignPage />,
                },
                {
                  path: 'service',
                  children: [
                    {
                      path: '/dashboard/home/construction-design/service',
                      element: <ServiceConstructionDesignListPage />,
                    },
                    {
                      path: '/dashboard/home/construction-design/service/new',
                      element: <CreateServiceConstructionDesignPage />,
                    },
                    {
                      path: '/dashboard/home/construction-design/service/:id',
                      element: <EditServiceConstructionDesignPage />,
                    },
                  ],
                },
                {
                  path: 'procedure',
                  element: <ProcedureConstructionDesignPage />,
                },
                // {
                //   path: '/dashboard/home/event/new',
                //   element: <CreateEventPage />,
                // },
                // {
                //   path: '/dashboard/home/event/edit/:id',
                //   element: <EditEventPage />,
                // },
              ],
            },
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },

        { path: 'files-manager', element: <FileManagerPage /> },

        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Menu />, index: true }],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
