import i18next from 'i18next';
import { TypeDefault } from '../@types/projectImage';

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  LOCALE: 'i18nextLng',
  ADMIN_TOKEN: 'admin-token',
};

export const TYPE_NOTICE = {
  basicType: 'basic',
  contactType: 'contact',
  profileType: 'profile',
  insuranceType: 'insurance',
  bankType: 'bank',
  taxType: 'tax',
  contractType: 'contract',
};

export const DEFAULT_PAGINATION = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  PAGE_ONE: 1,
  GET_ALL: 10000,
};

export const GETALL_PROVINCE = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 100,
};

export const GETALL_DISTRICT = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 1000,
};

export const EMPLOYEE_GENERAL_STATUS = {
  CONTRACT: {
    probationary: {
      label: i18next.t('probationaryPeriod'),
      value: 'probationary',
    },
    firstLaborContract: {
      label: i18next.t('isFirstLaborContract'),
      value: 'firstLaborContract',
    },
    secondLaborContract: {
      label: i18next.t('isSecondLaborContract'),
      value: 'secondLaborContract',
    },
    infinite: {
      label: i18next.t('isInfinite'),
      value: 'infinite',
    },
    service: {
      label: i18next.t('isService'),
      value: 'service',
    },
    training: {
      label: i18next.t('isTraining'),
      value: 'training',
    },
  },
  INCOMPLETE: {
    contact: {
      label: i18next.t('contactInfo'),
      value: 'contact',
    },
    bank: {
      label: i18next.t('bankingInfo'),
      value: 'bank',
    },
    insurance: {
      label: i18next.t('insuranceInformation'),
      value: 'insurance',
    },
    tax: {
      label: i18next.t('incomeTaxInformation'),
      value: 'tax',
    },
    profile: {
      label: i18next.t('profileInformation'),
      value: 'profile',
    },
  },
};
export const OBJECT_TYPE = {
  source: {
    internalSource: 'internalSource',
    externalSource: 'externalSource,',
  },
  experience: {
    saleExperience: 'saleExperience',
    office: 'office',
    market: 'market',
    warehouseAndDelivery: 'warehouseAndDelivery',
  },
  tax: {
    contractStatus: 'contractStatus',
  },
  insurance: {
    insuranceStatus: 'insuranceStatus',
  },
  bank: {
    bankStatus: 'bankStatus',
  },
  recruitment: {
    job: 'recruitmentJob',
  },
  otherService: {
    gift: 99,
    furnitureProduct: 98,
  },
  service: {
    event: 97,
    constructionDesign: 96,
  },
};

export const HRM_OPTIONS = [
  {
    label: i18next.t('recruitment'),
    value: '32',
    isDisable: false,
  },
  {
    label: i18next.t('hrm'),
    value: '33',
    isDisable: true,
  },
  {
    label: i18next.t('managementSystem'),
    value: '34',
    isDisable: true,
  },
];

export const SOURCE_OPTIONS = [
  {
    label: i18next.t('internalSource'),
    value: 'internalSource',
  },
  {
    label: i18next.t('externalSource'),
    value: 'externalSource',
  },
];

export const EXPERIENCE_OPTIONS = [
  {
    label: i18next.t('saleExperience'),
    value: 'saleExperience',
  },
  {
    label: i18next.t('office'),
    value: 'office',
  },
  {
    label: i18next.t('market'),
    value: 'market',
  },
  {
    label: i18next.t('warehouseAndDelivery'),
    value: 'warehouseAndDelivery',
  },
];

export const CONTRACT_STATUS = [
  {
    label: EMPLOYEE_GENERAL_STATUS.CONTRACT.probationary.label,
    value: EMPLOYEE_GENERAL_STATUS.CONTRACT.probationary.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.CONTRACT.firstLaborContract.label,
    value: EMPLOYEE_GENERAL_STATUS.CONTRACT.firstLaborContract.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.CONTRACT.secondLaborContract.label,
    value: EMPLOYEE_GENERAL_STATUS.CONTRACT.secondLaborContract.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.CONTRACT.infinite.label,
    value: EMPLOYEE_GENERAL_STATUS.CONTRACT.infinite.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.CONTRACT.service.label,
    value: EMPLOYEE_GENERAL_STATUS.CONTRACT.service.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.CONTRACT.training.label,
    value: EMPLOYEE_GENERAL_STATUS.CONTRACT.training.value,
  },
];

export const INCOMPLETE_STATUS = [
  {
    label: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.contact.label,
    value: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.contact.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.bank.label,
    value: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.bank.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.insurance.label,
    value: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.insurance.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.tax.label,
    value: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.tax.value,
  },
  {
    label: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.profile.label,
    value: EMPLOYEE_GENERAL_STATUS.INCOMPLETE.profile.value,
  },
];

export const PRIORITY = [
  {
    label: i18next.t('highPriority'),
    value: 1,
  },
  {
    label: i18next.t('averagePriority'),
    value: 2,
  },
  {
    label: i18next.t('lowPriority'),
    value: 3,
  },
];

export const OBJECT_TYPES = {
  product: {
    productType: 'productType',
    tags: 'tags',
    productCategories: 'productCategories',
  },
  constructionDesign: {
    introduce: 'constructionDesignIntroduce',
    procedure: 'constructionDesignProcedure',
  },
  events: {
    introduce: 'eventsIntroduce',
    procedure: 'eventsProcedure',
  },
};

// background color
export const backgroundColor = {
  main: '#D8F3DC',
  white: '#fff',
};

export const S3_PROJECT = 'hunglongbranding';
export const PRODUCT_HAS_CHILDREN = 11;
export const PRODUCT_ADVERTISING_FURNITURE = 8;
export const PRODUCT_INTERIOR_HOME = 9;

export const type: {
  [key in TypeDefault]: {
    link?: string;
    size: string;
    totalImage: number;
  };
} = {
  menu: {
    size: '302 × 640',
    totalImage: 4,
  },
  homeSectionOne: {
    size: '1648 × 932',
    totalImage: 1,
  },
  homeSectionTwo: {
    size: '1: 474 × 348, 2: 489 x 736',
    totalImage: 2,
  },
  homeSectionThree: {
    size: '1032 × 736',
    totalImage: 1,
  },
  homeSectionFour: {
    size: '1: 792 x 546, 2: 474 × 344',
    totalImage: 2,
  },
  homeSectionFive: {
    size: '960 × 616',
    totalImage: 1,
  },
  homeSectionSix: {
    size: '1040 × 564',
    totalImage: 1,
  },

  homeAboutUs: {
    link: 'https://hunglongbranding.com/',
    size: '1: 455 × 305 , 2-3: 338 × 214',
    totalImage: 3,
  },
  homeBanners: {
    link: 'https://hunglongbranding.com/#partner',
    size: '1440 × 720',
    totalImage: 88,
  },
  homePartner: {
    link: 'https://hunglongbranding.com/#partner',
    size: '108 × 32',
    totalImage: 88,
  },
  aboutUsCompany: {
    size: '599 × 320',
    totalImage: 1,
  },
  aboutUsBackground: {
    size: '1: 570 × 700, 2: 385 x 420',
    totalImage: 2,
  },
  aboutUsActivity: {
    link: 'https://hunglongbranding.com/about',
    size: '237 x 168',
    totalImage: 3,
  },
  storeBanner: {
    link: 'https://hunglongbranding.com/store',
    size: '358 x 240',
    totalImage: 3,
  },
  storeBackground: {
    link: 'https://hunglongbranding.com/store',
    size: '1440 × 444',
    totalImage: 1,
  },
  storeSale: {
    link: 'https://hunglongbranding.com/store/general',
    size: '230 × 360',
    totalImage: 1,
  },
  serviceAdvertiseBg: {
    link: 'https://hunglongbranding.com/advertise-furniture',
    size: '1440 × 444',
    totalImage: 1,
  },
  serviceHouseBg: {
    link: 'https://hunglongbranding.com/house-furniture',
    size: '1440 × 444',
    totalImage: 1,
  },
  serviceWebsiteBg: {
    link: 'https://hunglongbranding.com/website-and-system',
    size: '1440 × 444',
    totalImage: 1,
  },
  serviceOtherBg: {
    link: 'https://hunglongbranding.com/other-service',
    size: '1440 × 444',
    totalImage: 1,
  },
  contactBackground: {
    link: 'https://hunglongbranding.com/contact',
    size: '1440 × 444',
    totalImage: 1,
  },
};

export const JOB_TYPE = [
  {
    label: 'Toàn thời gian',
    value: 'Toàn thời gian',
  },
  {
    label: 'Bán thời gian',
    value: 'Bán thời gian',
  },
  // {
  //   label: 'Freelancer',
  //   value: 'Freelancer',
  // },
];

export const JOB_LEVEL = [
  {
    label: 'Fresher',
    value: 'Fresher',
  },
  {
    label: 'Junior',
    value: 'Junior',
  },
  {
    label: 'Middle',
    value: 'Middle',
  },
  {
    label: 'Senior',
    value: 'Senior',
  },
];

export const WORKING_TYPE = [
  {
    label: 'Remote',
    value: 'remote',
  },
  {
    label: 'Hybrid',
    value: 'hybrid',
  },

  {
    label: 'Tại văn phòng',
    value: 'office',
  },
];

// color
export const textColor = {
  white: '#fff',
  black: '#000',
};
