import i18next from 'i18next';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: i18next.t('systemManagement'),

    items: [
      {
        title: i18next.t('home'),
        path: PATH_DASHBOARD.app.root,
        icon: ICONS.label,
        children: [
          {
            title: i18next.t('editWebsite'),
            path: PATH_DASHBOARD.app.editWebsite.root,
            children: [
              { title: i18next.t('menu'), path: PATH_DASHBOARD.app.editWebsite.menu },
              { title: i18next.t('home'), path: PATH_DASHBOARD.app.editWebsite.home },
              { title: i18next.t('aboutUs'), path: PATH_DASHBOARD.app.editWebsite.aboutUsPage },
              {
                title: i18next.t('constructionDesign'),
                path: PATH_DASHBOARD.app.editWebsite.constructionDesign,
              },
              { title: i18next.t('events'), path: PATH_DASHBOARD.app.editWebsite.events },
              { title: i18next.t('hrm'), path: PATH_DASHBOARD.app.editWebsite.hrm },
              {
                title: i18next.t('serviceOther'),
                path: PATH_DASHBOARD.app.editWebsite.serviceOther,
              },
            ],
          },
          // {
          //   title: i18next.t('editImage'),
          //   path: PATH_DASHBOARD.app.pageImage.pageImage,
          //   children: [
          //     {
          //       title: i18next.t('menu'),
          //       path: PATH_DASHBOARD.app.editWebsite.menu,
          //     },
          //     {
          //       title: i18next.t('home'),
          //       path: PATH_DASHBOARD.app.editWebsite.homePage,
          //     },
          //     {
          //       title: i18next.t('aboutUs'),
          //       path: PATH_DASHBOARD.app.editWebsite.aboutUs,
          //     },
          //   ],
          // },
          {
            title: i18next.t('constructionDesign'),
            path: PATH_DASHBOARD.app.constructionDesign.root,
            children: [
              {
                title: i18next.t('introduce'),
                path: PATH_DASHBOARD.app.constructionDesign.introduce,
              },
              {
                title: i18next.t('service'),
                path: PATH_DASHBOARD.app.constructionDesign.service.service,
              },
              {
                title: i18next.t('procedure'),
                path: PATH_DASHBOARD.app.constructionDesign.procedure,
              },
            ],
          },
          {
            title: i18next.t('events'),
            path: PATH_DASHBOARD.app.events.events,
            children: [
              {
                title: i18next.t('introduce'),
                path: PATH_DASHBOARD.app.events.introduce,
              },
              {
                title: i18next.t('service'),
                path: PATH_DASHBOARD.app.events.service.service,
              },
              {
                title: i18next.t('procedure'),
                path: PATH_DASHBOARD.app.events.procedure,
              },
            ],
          },
          {
            title: i18next.t('hrm'),
            path: PATH_DASHBOARD.app.hrm.root,
            children: [
              {
                title: i18next.t('job'),
                path: PATH_DASHBOARD.app.hrm.recruitment.job.root,
              },
              {
                title: i18next.t('listOfCV'),
                path: PATH_DASHBOARD.app.hrm.recruitment.apply.apply,
              },
            ],
          },
          {
            title: i18next.t('otherService'),
            path: PATH_DASHBOARD.app.otherService.root,
            children: [
              {
                title: i18next.t('gift'),
                path: PATH_DASHBOARD.app.otherService.gift.root,
              },
              {
                title: i18next.t('furnitureProduct'),
                path: PATH_DASHBOARD.app.otherService.furnitureProduct.root,
              },
            ],
          },

          {
            title: i18next.t('contact'),
            path: PATH_DASHBOARD.app.contact.contact,
          },
        ],
      },
    ],
  },
];

export default navConfig;
