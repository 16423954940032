import { IWebcontent } from '../@types/webcontent';
import { getAsync, postAsync } from './http-client';

const api = '/webcontent';

const webcontentApi = {
    get: (params: string) => {
        return getAsync(`${api}`, { type: params });
    },
    post: (data: IWebcontent) => {
        return postAsync(`${api}`, [data]);
    },
};
export default webcontentApi;
