import i18next from 'i18next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import snackbar from 'utils/snackbar';
import careersApi from 'apis/careers.api';
import contactApi from 'apis/contact.api';
import { CareerState, ICareer, ICareerCreated, IParamsJob } from '../../../@types/job';
import { ParamsType } from '../../../@types/paramsType';
import objectType from './objectType';
import ObjectType from '../../../apis/objecType.api';
import { OBJECT_TYPE } from '../../../constants/app.constants';

export const getListCareer = createAsyncThunk(
  'career/getList',
  async (params: IParamsJob, { dispatch }) => {
    const { data } = await careersApi.getList(params);
    return data;
  }
);

export const getObjectType = createAsyncThunk(
  'career/getObjectType',
  async (objectTypeProps: string, { dispatch }) => {
    const { data } = await ObjectType.get({ objectType: objectTypeProps });
    return data;
  }
);

export const getObjectTypeOfCarrer = createAsyncThunk(
  'career/getObjectTypeOfCarrer',
  async (_, { dispatch }) => {
    const { data } = await ObjectType.get({ objectType: OBJECT_TYPE.recruitment.job });
    return data;
  }
);

export const deleteCareer = createAsyncThunk(
  'career/delete',
  async (id: string[], { dispatch }) => {
    await careersApi.delete(id);
    snackbar.success(i18next.t('deleteSuccess'));
  }
);

export const createCareer = createAsyncThunk(
  'career/create',
  async (data: ICareerCreated, { dispatch }) => {
    await careersApi.post(data);
    if (data.id === 0) {
      snackbar.success(i18next.t('createSuccess'));
    } else {
      snackbar.success(i18next.t('updateSuccess'));
    }
  }
);

export const getDetailCareer = createAsyncThunk(
  'career/getDetail',
  async (id: string, { dispatch }) => {
    const { data } = await careersApi.getDetail(id);
    return data;
  }
);

const initialState: CareerState = {
  listCareer: [],
  careerCount: 0,
  careerDetail: {
    id: 0,
    province_id: 0,
    name: '',
    jobType: '',
    content: '',
    description: '',
    level: '',
    provinceName: '',
    thumbnail: '',
    workingType: '',
    field: '',
  },
  objectTypeCarrer: 0,
};

const slice = createSlice({
  name: 'career',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListCareer.fulfilled, (state, action) => {
        state.listCareer = action.payload.items;
        state.careerCount = action.payload.totalRow;
      })

      .addCase(getDetailCareer.fulfilled, (state, action) => {
        state.careerDetail = action.payload;
      })
      .addCase(getObjectTypeOfCarrer.fulfilled, (state, action) => {
        state.objectTypeCarrer = action.payload[0].id;
      });
  },
});
export default slice.reducer;
