import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import eventReducer from './slices/dashboard/event';
import objectTypeReducer from './slices/dashboard/objectType';
import orderReducer from './slices/dashboard/order';
import productReducer from './slices/dashboard/product';
import giftReducer from './slices/dashboard/gift';
import furnitureProductReducer from './slices/dashboard/furniture-product';
import contactReducer from './slices/dashboard/contact';
import projectImageReducer from './slices/dashboard/projectImage';
import serviceEventReducer from './slices/dashboard/serviceEvent';
import provinceReducer from './slices/dashboard/province';
import careerReducer from './slices/dashboard/career';
import careerApplyReducer from './slices/dashboard/apply-careers';
import webcontentReducer from './slices/dashboard/webcontent';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  order: orderReducer,
  objectType: objectTypeReducer,
  product: productReducer,
  gift: giftReducer,
  furnitureProduct: furnitureProductReducer,
  event: eventReducer,
  contact: contactReducer,
  projectImage: projectImageReducer,
  serviceEvent: serviceEventReducer,
  province: provinceReducer,
  career: careerReducer,
  careerApply: careerApplyReducer,
  webcontent: webcontentReducer,
});

export default rootReducer;
