// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  search:'Search',
  selected:'selected',
  role:'Role',
  app: `app`,
  user: `user`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,

  // HRM
  hrm: 'HRM',
  systemManagement: 'System Management',
  employeeList: 'Employee List',
  recruitment: 'Recruitment',
  attendance: 'Attendance',
  training: 'Training',

  payroll: 'Payroll',
  performances: 'Performances',
  recruitmentAndTraining: 'Recruitment & Training',
  employeeManagement: 'Employee Management',
  employeeStatus: 'List',
  asset: 'Asset',
  compensationAndBenefit: 'Compensation and Benefit',
  payslip: 'Payslip',
  okr: 'OKR',
  taskKpi: 'Task/KPI',
  jobPosting: 'Job posting',
  management: 'Management',
  jobSeeking: 'Job seeking',
  employees: 'Employees',
  trainerAdmin: 'Trainer/ Admin',
  summaryCount: 'Summary Count',

  male: 'Male',
  female: 'Female',
  mosGreaterThan6: 'MOS (>= 6)',
  mosSmallerThan6: 'MOS (< 6)',

  warehouseDepartment: 'Warehouse Department',
  hrDepartment: 'HR Department',
  itDepartment: 'IT Department',
  financeDepartment: 'Finance Department',
  salesDepartment: 'Sales Department',

  employeeByDepartment: 'Employee By Department',
  employee: 'Employee',
  new: 'New',
  dashboard: 'Dashboard',
  listOfEmployee: 'List of Employee',
  list: 'List',

  createAnEmployee: 'Create an employee',
  create: 'Create',
  basicInfo: 'Basic Info',
  staffInfo: 'Staff Info',
  salaryInfo: 'Salary Info',
  bankAccountInfo: 'Bank Account Info',
  systemUserAccountInfo: 'System User Account Info',
  firstName: 'First Name',
  lastName: 'Last Name',
  gender: 'Gender',
  dateOfBirth: 'Date of Birth',
  placeOfBirth: 'Place of Birth',
  maritalStatus: 'Marital Status',
  email: 'Email',
  phone: 'Phone',
  streetAddress1: 'Street Address1',
  streetAddress2: 'Street Address2',
  city: 'City',
  stateProvince: 'State / Province',
  zipCode: 'ZipCode / Postal Code',
  country: 'Country',
  employeeIdNumber: 'Employee ID Number',
  designation: 'Designation',
  department: 'Department',
  supervisor: 'Supervisor',
  joiningDate: 'Joining Date',
  leavingDate: 'Leaving Date',
  basicSalary: 'Basic Salary',
  unPaidLeavePerDay: 'Un Paid Leave Per Day',
  benefitPackageTemplate: 'Benefit Package Template',
  accountTitle: 'Account Title',
  bankName: 'Bank Name',
  accountNumber: 'Account Number',
  swiftCode: 'Swift Code',
  systemApplicationUser: 'System Application User',
  none: 'None',
  back: 'Back',
  save: 'Save',
  single: 'Single',
  married: 'Married',
  remarried: 'Remarried',
  separated: 'Separated',
  divorced: 'Divorced',
  widowed: 'Widowed',
  quickGuide: 'Quick Guide',

  employeeQuickGuideDesc:
    'Employee module will provide tools to manage employee data. Register new employee and configure their basic info is one of the module capabilities.',

  employeeFeature: 'Employee Module Feature:',

  registerNewEmployee: 'Register New Employee',
  setupEmployeeBasicInfo: 'Setup Employee Basic Info',
  setupEmployeeStaffInfo: 'Setup Employee Staff Info',
  setupEmployeeSalaryInfo: 'Setup Employee Salary Info',
  setupEmployeeBankAccountInfo: 'Setup Employee Bank Account Info',
  setupEmployeeSystemUserAccount: 'Setup Employee System User Account',
  manageDepartmentDataMaster: 'Manage Department Data Master',
  manageDesignationDataMaster: 'Manage Designation Data Master',

  listOfAttendance: 'List of Attendance',
  absenceByDepartment: 'Absence By Department',
  presence: 'Presence',
  absence: 'Absence',
  paidLeave: 'Paid Leave',
  notPaidLeave: 'Not Paid Leave',

  attendanceQuickGuideDesc:
    'Attendance module will provide features to entry or edit presence of an employee.',
  attendanceFeature: 'Attendance Features:',
  clockInOut: 'Clock In / Out',
  registerPublicHoliday: 'Register Public Holiday',
  createAttendance: 'Create an attendance',
  attendanceInfo: 'Attendance Info',
  attendanceName: 'Attendance Name',
  clock: 'Clock',
  attendanceDescription: 'Attendance Description',
  onBehalf: 'OnBehalf',
  contract: 'Contract',

  validate: {
    employee: {
      firstName: 'Please enter first name',
      lastName: 'Please enter last name',
      gender: 'Please select gender',
      email: 'Please enter email',
      invalidEmail: 'Email is invalid',
      phone: 'Please enter phone number',
      employeeIdNumber: 'Please enter Employee ID number',
    },
    attendance: {
      name: 'Please enter attendance name',
      clock: 'Please select time',
      desc: 'Please enter attendance description',
    },
  },

  // Basic employee information
  agariEmployeeId: 'Agari Employee Id',
  employeeIdInProject: 'Employee Id in Project',
  idCard: 'Identity Card / Citizen Identification',
  typeOfDocument: 'Type of document',
  documentTypeIdCard: 'Personal identification document',
  dateOfIssue: 'Date of issue',
  placeOfIssue: 'Place of issue',
  province: 'Province',
  provinceCode: 'Province Code',
  district: 'District',
  districtCode: 'District Code',
  ward: 'Ward',
  wardCode: 'Ward Code',
  permanentAddress: `Permanent address`,
  temporaryResidenceAddress: 'Temporary residence address',
  street: 'Street',
  nationality: `Nationality`,
  ethnicGroup: 'Ethnic Group',
  religion: 'Religion',
  education: 'Education',
  major: 'Major',
  basic: 'Basic Information',
  contactInfo: 'Contact Information',
  emergencyContactNumber: 'Emergency contact number',
  // Bank infomation
  bankingInfo: 'Banking Infomation',
  accountHolderName: 'Account holder name',
  bankAccountNumber: 'Bank account number',
  bankNameAbbreviation: 'Bank name abbreviation',
  provinceCityBank: 'Province/city where the bank branch is located',
  bankBranchName: 'Bank branch name',
  // Social insurance registration information
  insuranceInformation: 'Social insurance registration information',
  householdCode: 'Household Code',
  socialInsuranceNumber: 'Social insurance number',
  medicalFacilityCode: ' Medical facility code',
  medicalFacilityName: 'Medical facility name',
  // Personal Income Tax Information
  incomeTaxInformation: 'Personal Income Tax Information',
  taxCode: 'Tax Code',
  numbersofDependents: 'Number of dependents',
  relationshipPersonalDeduction: 'Relationship registered for personal deduction',
  certificateOfRelationship: 'Certificate of relationship',
  // Activity Status
  activityStatus: 'Activity status',
  working: 'Working',
  waitingForEmployment: 'Waiting for employment',
  planingToResign: 'Planning to resign',
  alreadyResigned: 'Already resigned',
  onTemporaryLeave: 'On temporary leave',
  maternityLeave: 'Maternity leave',
  startDate: 'Start Date',
  endDate: 'End Date',
  currentContract: 'Current contract',
  departmentProject: 'Department/Project',
  region: 'Region/Area',
  position: 'Position/Job title',
  probationaryPeriod: 'Probationary period',
  regularEmployment: 'Regular employment',
  // Profile information
  profileInformation:'Profile Information',
  applicationForm:'Application Form',
  certifiedResume:'Certified resume',
  certifiedIdCard:'Certified ID card',
  certifiedHouseholdRegistration:'Certified household registration',
  healthCertificate:'Health certificate',
  commitmentLetter:'Commitment Letter',
  diploma:'Diploma',
  trainingCertificate:'Training Certificate (Optional)',
  others:'Others',
  resignationLetter:'Resignation Letter',
  submitted:'Submitted',
  notSubmitted:'Not submitted',
  submittedIncorrectly:'Submitted incorrectly',
  notRequired:'Not required',
  approachingDeadline:"Approaching Deadline",
};

export default en;
