// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vi = {
  demo: {
    title: `Vietnamese`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `tài liệu`,
  },
  projectImage: 'Hình ảnh dự án',
  nameProject: 'Tên dự án',
  descProject: 'Mô tả dự án',
  typeDetailId: 'Chi tiết loại sản phẩm',
  dense: 'Thu gọn',
  dropSelectFile: 'Thả file vào đây hoặc nhấp để chọn',
  emptyProjectImage: 'Không có hình ảnh dự án',
  // dropSelectHere: "Thả vào đây hoặc nhấp để chọn",
  loginFailed: 'Đăng nhập thất bại',
  login: 'Đăng nhập',
  forgotPassword: 'Quên mật khẩu?',
  home: 'Trang chủ',
  logout: 'Đăng xuất',
  signInTo: 'Đăng nhập với',
  removeAll: `Xóa tất cả`,
  createSuccess: 'Tạo mới thành công',
  deleteSuccess: 'Xóa thành công',
  insertSuccess: 'Thêm mới thành công',
  updateSuccess: 'Cập nhật thành công',
  salary: 'Mức lương',
  takePicture: 'Chụp ảnh',
  identifiNumber: 'Số định danh cá nhân',
  file: 'Tệp',
  noFile: 'Không có tệp',
  attach: 'Tệp đính kèm',
  image: 'Hình ảnh',
  specifications: 'Thông số kỹ thuật',
  Capture: 'Chụp',
  takeAvatarPicture: 'Chụp ảnh chân dung',
  chooseFile: 'Vui lòng chọn file',
  importCreate: 'Import Tạo mới nhân viên',
  importUpdate: 'Import Update nhân viên',
  degree: 'Bằng cấp',
  otherHealthy: 'Giấy khám sức khỏe (khác theo các TT)',
  notEnough16: 'Công dân chưa đủ 16 tuổi',
  alertYearsOld: 'Công dân chưa đủ 18 tuổi',
  back: 'Quay lại',
  selected: 'Đã được chọn',
  createAnEmployee: 'Tạo nhân viên mới',
  clear: 'Xóa',
  email: 'Email',
  app: `ứng dụng`,
  user: `Người dùng`,
  list: `Danh sách`,
  edit: `Chỉnh sửa`,
  editSuccess: `Chỉnh sửa thành công`,
  delete: 'Xóa',
  deleteConfirm: 'Bạn có chắc chắn muốn xóa?',
  deleteConfirmItems: 'Bạn có chắc chắn muốn xóa {{count}} đơn hàng?',
  name: 'Họ và tên',
  phone: 'Số điện thoại',
  cancel: 'Hủy',
  shop: `cửa hàng`,
  blog: `blog`,
  post: `bài viết`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách bài viết`,
  kanban: `bảng`,
  general: `Tổng quan`,
  banking: `Ngân hàng`,
  booking: `đặt phòng`,
  account: `Tải khoản`,
  product: `Sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  search: 'Tìm kiếm',
  menu: `Menu`,
  menu_level: `menu`,
  menu_level_2a: `menu cấp 2a`,
  menu_level_2b: `menu cấp 2b`,
  menu_level_3a: `menu cấp 3a`,
  menu_level_3b: `menu cấp 3b`,
  menu_level_4a: `menu cấp 4a`,
  menu_level_4b: `menu cấp 4b`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  item_external_link: `mục liên kết ngoài`,
  description: `Mô tả`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
  systemManagement: 'Hệ thống quản lý',
  employeeManagement: 'Quản lý nhân viên',
  employeeStatus: 'Danh sách',
  profile: 'Hồ sơ',
  dashboard: 'Trang chủ',
  employee: 'Nhân viên',
  summaryCount: 'Tổng số lượng',
  listOfEmployee: 'Danh sách nhân viên',
  settingProjectImage: 'Thiết lập hình ảnh dự án',
  settingBanner: 'Thiết lập hình ảnh banner',
  listOfContact: 'Danh sách liên hệ',
  role: 'Vai trò',
  close: 'Đóng',
  create: 'Tạo mới',
  add: 'Thêm mới',
  preview: 'Xem trước',
  view: 'Xem',
  area: 'Khu vực',
  relationship: 'Mối quan hệ',
  // Geneal
  // hrm: 'HRM',
  employeeByDepartment: 'Nhân viên theo phòng ban',
  warehouseDepartment: 'Bộ phận kho',
  hrDepartment: 'Phòng nhân sự',
  itDepartment: 'Phòng CNTT',
  financeDepartment: 'Phòng tài chính',
  salesDepartment: 'Phòng kinh doanh',
  mosSmallerThan6: 'MOS (<6)',
  mosGreaterThan6: 'MOS (>6)',
  day: 'Ngày',
  month: 'Tháng',
  year: 'Năm',
  new: 'Tạo mới',
  sizeBetter: 'Kích thước tốt nhất: 361 x 340',
  sizeBetterIs: 'Kích thước tốt nhất:',
  // Đăng ký tài khoản nhân viên
  employeeSignUp: 'Đăng ký tài khoản',
  username: 'Tài khoản',
  password: 'Mật khẩu',
  verifyPassword: 'Nhập lại mật khẩu',
  // EmployeeQuickGuide
  employeeQuickGuideDesc:
    'Module Nhân viên cung cấp các công cụ để quản lý dữ liệu nhân viên. Một trong những chức năng của mô-đun là đăng ký nhân viên mới và cấu hình thông tin cơ bản của họ.',
  employeeFeature: 'Các tính năng của Module Nhân viên bao gồm:',
  registerNewEmployee: 'Đăng ký nhân viên mới',
  setupEmployeeBasicInfo: 'Thiết lập thông tin cơ bản của nhân viên',
  setupEmployeeStaffInfo: 'Thiết lập thông tin về nhân sự của nhân viên',
  setupEmployeeSalaryInfo: 'Thiết lập thông tin lương của nhân viên',
  setupEmployeeBankAccountInfo: 'Thiết lập thông tin tài khoản ngân hàng của nhân viên',
  setupEmployeeSystemUserAccount: 'Thiết lập tài khoản người dùng hệ thống cho nhân viên',
  manageDepartmentDataMaster: 'Quản lý dữ liệu danh mục Phòng ban',
  manageDesignationDataMaster: 'Quản lý dữ liệu danh mục Chức danh',
  addProduct: 'Thêm sản phẩm',
  pageImage: 'Hình ảnh trang web',
  settingPageImage: 'Thiết lập hình ảnh trang web',
  nameSettingImagePage: 'Vị trí thiết lập hình ảnh',
  aboutUs: 'Về chúng tôi',
  service: 'Dịch vụ',
  store: 'Cửa hàng',
  contact: 'Liên hệ',
  storeBanner: 'Banner sản phẩm',
  aboutUsCompany: 'Hình ảnh giới thiệu công ty',
  aboutUsBackground: 'Background về chúng tôi',
  page: 'Trang',
  storeBackground: 'Background cửa hàng',
  storeSale: 'Poster thanh lý và ưu đãi',
  serviceAdvertiseBg: 'Background nội thất quảng cáo',
  serviceHouseBg: 'Background nội thất nhà cửa',
  serviceWebsiteBg: 'Background website và hệ thống',
  serviceOtherBg: 'Background dịch vụ khác',
  contactBackground: 'Background liên hệ',
  required: 'Bắt buộc phải có',
  maxHave: 'Tối đa có',
  picture: 'ảnh',
  // Validation
  validate: {
    numberRequired: 'Vui lòng nhập số',
    numberRange: 'Số phải nằm trong khoảng từ 0 đến 100',
    numberValid: 'Số không hợp lệ',
    emailInvalid: 'Email không hợp lệ',
    emailRequired: 'Vui lòng nhập email',
    phoneRequired: 'Vui lòng nhập số điện thoại',
    phoneInvalid: 'Số điện thoại không hợp lệ',
    factoryAddressRequired: 'Vui lòng nhập địa chỉ nhà máy',
    linkFacebookRequired: 'Vui lòng nhập link Facebook',
    linkInstagramRequired: 'Vui lòng nhập link Instagram',
    linkZaloRequired: 'Vui lòng nhập link Zalo',
    addressRequired: 'Vui lòng nhập địa chỉ',
    titleRequired: 'Vui lòng nhập tiêu đề',
    bannerRequired: 'Vui lòng chọn Banner',
    banner: {
      maxTitle: 'Tiêu đề không được vượt quá 30 ký tự',
      maxSubTitle: 'Phụ đề không được vượt quá 30 ký tự',
    },
    auth: {
      usernameRequired: 'Vui lòng nhập tài khoản',
      passwordRequired: 'Vui lòng nhập mật khẩu',
    },
    projectImage: {
      nameRequired: 'Vui lòng nhập tên dự án',
      minImage: 'Hình ảnh dự án phải có ít nhất 1 hình ảnh',
      maxName: 'Tên dự án không vượt quá 25 ký tự',
      maxDescription: 'Mô tả dự án không vượt quá 50 ký tự',
    },
    order: {
      // Required
      nameRequired: 'Vui lòng nhập tên',
      emailRequired: 'Vui lòng nhập email',
      phoneRequired: 'Vui lòng nhập số điện thoại',
      addressRequired: 'Vui lòng nhập địa chỉ',
      quantityRequired: 'Vui lòng nhập số lượng',
      noteRequired: 'Vui lòng nhập ghi chú',
      productRequired: 'Vui lòng chọn sản phẩm',
      // Length
      maxName: 'Tên không được vượt quá 50 ký tự',
      minPhone: 'Số điện thoại phải có ít nhất 10 ký tự',
      maxPhone: 'Số điện thoại không được vượt quá 11 ký tự',
      quantityMax: 'Số lượng không được vượt quá 100',
      // Valid
      emailValid: 'Email không hợp lệ',
      phoneValid: 'Số điện thoại không hợp lệ',
      quantityInvalid: 'Số lượng không hợp lệ',
      // Number
      quantityNumber: 'Số lượng phải là số',
      quantityPositive: 'Số lượng phải lớn hơn 0',
    },
    event: {
      // Required
      nameRequired: 'Vui lòng nhập tên sự kiện',
      descriptionRequired: 'Vui lòng nhập mô tả sự kiện',
      contentRequired: 'Vui lòng nhập nội dung sự kiện',
      thumbnailRequired: 'Vui lòng chọn ảnh đại diện',
      eventTypeRequired: 'Vui lòng chọn loại sự kiện',
      // Length
      maxName: 'Tên sự kiện không được vượt quá 50 ký tự',
    },
    product: {
      // Required
      productNameRequired: 'Vui lòng nhập tên sản phẩm',
      productTypeRequired: 'Vui lòng chọn loại sản phẩm',
      productDescriptionRequired: 'Vui lòng nhập mô tả sản phẩm',
      thumbnailRequired: 'Vui lòng chọn ảnh đại diện',
      numberRequired: 'Phải là số',
      maxName: 'Tên sản phẩm không được vượt quá 50 ký tự',
      lessPrice: 'Giá khuyến mãi phải nhỏ hơn giá gốc',
      typeDetailIdRequired: 'Vui lòng chọn chi tiết sản phẩm',
    },
    gift: {
      giftNameRequired: 'Vui lòng nhập tên quà tặng',
      maxName: 'Tên quà tặng không vượt quá 50 ký tự',
      materialValueRequired: 'Vui lòng nhập chất liệu',
      specificationsValueRequired: 'Vui lòng nhập thông số kỹ thuật',
      materialValueMaxRequired: 'Chất liệu không được vượt quá 50 ký tự',
      maxNameSpecificationsValueRequired: 'Thông số kỹ thuật không được vượt quá 50 ký tự',
    },
    furnitureProduct: {
      furnitureProductNameRequired: 'Vui lòng nhập tên sản phẩm nội thất',
      maxName: 'Tên sản phẩm nội thất không vượt quá 50 ký tự',
      materialValueRequired: 'Vui lòng nhập chất liệu',
      specificationsValueRequired: 'Vui lòng nhập thông số kỹ thuật',
    },
    career: {
      brandRequired: 'Vui chọn loại tuyển dụng',
      nameCareerRequired: 'Vui lòng nhập tên công việc',
      addressCareerRequired: 'Vui lòng nhập địa chỉ làm việc',
      levelCareerRequired: 'Vui lòng chọn cấp độ công việc',
      provinceRequired: 'Vui lòng chọn tỉnh/thành phố',
      jobTypeRequired: 'Vui lòng chọn loại hình công việc',
      workingTypeCareerRequired: 'Vui lòng chọn phương thức làm việc',
    },
  },
  auth: {
    usernameRequired: 'Vui lòng nhập tên tài khoản',
    passwordRequired: 'Vui lòng nhập mật khẩu',
  },
  // Order
  fullName: 'Họ và tên',
  phoneNumber: 'Số điện thoại',
  address: 'Địa chỉ',
  note: 'Ghi chú',
  qty: 'Số lượng',
  order: 'Đơn hàng',
  createOrder: 'Tạo đơn hàng',
  orderId: 'Mã Đơn Hàng',
  createdAt: 'Ngày tạo',
  listOfOrder: 'Danh sách đơn hàng',
  editOrder: 'Chỉnh sửa đơn hàng',
  apply: 'Áp dụng',
  save: 'Lưu',
  // Product
  Categories: 'Categories',
  productId: 'Mã sản phẩm',
  tags: 'Tags',
  material: 'Chất liệu',
  salePrice: 'Giá khuyến mãi',
  price: 'Giá',
  productName: 'Tên sản phẩm',
  productType: 'Loại sản phẩm',
  createProduct: 'Tạo mới sản phẩm',
  listOfProduct: 'Danh sách sản phẩm',
  images: 'Hình ảnh',
  thumbnail: 'Thumbnail',
  liquid: 'Thanh lý',
  sale: 'Khuyến mãi',
  category: 'Danh mục',
  constructionList: 'Thông tin công trình',
  editProduct: 'Chỉnh sửa sản phẩm',
  editImage: 'Chỉnh sửa hình ảnh',
  unlimited: 'Không giới hạn ảnh',
  partner: 'Khách hàng thân thiết',
  activity: 'Hoạt động',
  // Event
  event: 'Sự kiện',
  typeEvent: 'Loại sự kiện',
  listOfEvent: 'Danh sách sự kiện',
  createEvent: 'Tạo sự kiện',
  editEvent: 'Chỉnh sửa sự kiện',
  eventId: 'Mã sự kiện',
  eventName: 'Tên sự kiện',
  eventType: 'Loại sự kiện',
  isActive: 'Hoạt động',
  isPublish: 'Công khai',
  content: 'Nội dung',
  hasActive: 'Đang hoạt động',
  noneActive: 'Không hoạt động',
  hasPublic: 'Công khai',
  nonePublic: 'Không công khai',
  infoList: 'Thông tin công trình',
  addInfoProduct: 'Thêm thông công trình',
  // Contact
  contactId: 'Mã liên hệ',
  webBanner: 'Banner trang chủ',
  websiteAndSystem: 'Nội dung',

  events: 'Tổ chức sự kiện',
  createService: 'Tạo dịch vụ',
  listOfService: 'Danh sách dịch vụ',
  serviceId: 'Mã dịch vụ',
  nameService: 'Tên dịch vụ',
  introduce: 'Giới thiệu',
  procedure: 'Quy trình',
  listOfJob: 'Danh sách công việc',
  job: 'Công việc',
  createJob: 'Tạo công việc',
  workDescription: 'Mô tả công việc',
  workRequirement: 'Yêu cầu công việc',
  workAddress: 'Địa chỉ làm việc',
  careerName: 'Tên công việc',
  workLevel: 'Cấp độ công việc',
  province: 'Tỉnh/Thành phố',
  jobType: 'Loại hình công việc',
  workingType: 'Phương thức làm việc',
  office: 'Tại văn phòng',
  editJob: 'Chỉnh sửa công việc',
  listOfCV: 'Đơn ứng tuyển',
  listOfJobApply: 'Danh sách đơn ứng tuyển',
  jobApply: 'Đơn ứng tuyển',
  giftId: 'Mã quà tặng',
  giftName: 'Tên quà tặng',
  gift: 'Quà tặng',
  otherService: 'Dịch vụ khác',
  listOfGift: 'Danh sách quà tặng',
  createGift: 'Tạo quà tặng',
  editGift: 'Chỉnh sửa quà tặng',
  furnitureProduct: 'Sản phẩm nội thất',
  listOfFurnitureProduct: 'Danh sách sản phẩm nội thất',
  furnitureProductId: 'Mã sản phẩm nội thất',
  furnitureProductName: 'Tên sản phẩm nội thất',
  createFurnitureProduct: 'Tạo sản phẩm nội thất',
  hrm: 'Quản lý nhân sự',
  recruitment: 'Tuyển dụng',
  homeSectionOne: 'Section 1',
  homeSectionTwo: 'Section 2',
  homeSectionThree: 'Section 3',
  homeSectionFour: 'Section 4',
  homeSectionFive: 'Section 5',
  homeSectionSix: 'Section 6',
  managementSystem: 'Hệ thống quản lý',
  hrmType: 'Loại tuyển dụng',
  editEventService: 'Chỉnh sửa dịch vụ',
  constructionDesign: 'Thiết kế & thi công',
  linkCV: 'Link CV',
  applyCareer: 'File ứng tuyển',
  bannerHome: 'Banner trang chủ',
  title: 'Tiêu đề',
  subtitle: 'Phụ đề',
  addBanner: 'Thêm banner',
  editHomePage: 'Chỉnh sửa trang chủ',
  factoryAddress: 'Địa chỉ nhà máy',
  linkFacebook: 'Link Facebook',
  linkInstagram: 'Link Instagram',
  linkZalo: 'Link Zalo',
  editPage: 'Chỉnh sửa trang',
  listImageActivity: 'Danh sách hình ảnh hoạt động',
  imageMap: 'Hình ảnh bản đồ',
  listActivity: 'Danh sách hoạt động',
  addActivity: 'Thêm hoạt động',
  editWebsite: 'Chỉnh sửa website',
  editAboutUsPage: 'Chỉnh sửa trang về chúng tôi',
  editConstructionDesign: 'Chỉnh sửa trang thiết kế & thi công',
  editEventOrganization: 'Chỉnh sửa trang tổ chức sự kiện',
  eventOrganization: 'Tổ chức sự kiện',
  editHRM: 'Chỉnh sửa trang HRM',
  serviceOther: 'Dịch vụ khác',
  editServiceOther: 'Chỉnh sửa trang dịch vụ khác',
  deleteConfirmItemsImportance: ` Nếu xóa item này sẽ cần phải thay đổi lại kích thước thumbnail của các item khác.
    \n Bạn có chắc chắn muốn xóa?`,
};

export default vi;
